.progressbarContainer {
    display: block;
    padding: 10px 10px;
    width: 100%;
    height: 100%;
}
.progressbarContainer .progressbarInner {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
    border-radius: 4px;
    box-shadow: 0 0 1px rgb(0 0 0 / 50%);
    overflow: hidden;
}
.progressbarContainer .progressbarInner .progressbarPercentage {
    display: block;
    height: 100%;
    background-color: #aaa;
}
.progressbarContainer[data-status="low"] .progressbarInner .progressbarPercentage {
    background-color: #fe8282;
}
.progressbarContainer[data-status="medium"] .progressbarInner .progressbarPercentage {
    background-color: #ffe200;
}
.progressbarContainer[data-status="high"] .progressbarInner .progressbarPercentage {
    background-color: #0ce500;
}
.progressbarContainer .progressbarInner .progressbarValue {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 1.2;
    font-size: 0.8rem;
}