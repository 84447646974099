.ln-to-br {
    white-space: pre-wrap;
}
.menu-item-title {
    position: relative;
}
.menu-item-title .chip {
    position: absolute;
    display: block;
    top: -3px;
    right: 0;
    font-weight: 200;
    line-height: 1.5rem;
}
.MuiAvatar-root {
    border-radius: 50%;
}
.MuiBreadcrumbs-root {
    padding: 0 0 10px 10px;
}
.MuiDataGrid-root {
    background-color: #ffffff;
}
.MuiDataGrid-root * {
    outline: none !important;
}
.MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
}
.campaign-collection-maincard .MuiCardContent-root {
    padding-bottom: 0;
}
.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 8px;
    padding-bottom: 8px;
}
.MuiTimeline-root.screenany-videoform-timeline li.MuiTimelineItem-root:before {
    display: none;
}
.MuiTimeline-root.screenany-videoform-timeline li.MuiTimelineItem-root .MuiTimelineSeparator-root {
    margin-top: 6px;
}
.video-form-builder-question-drawer .MuiPaper-root {
    background-color: #f5f7f7;
    border: 0;
    box-shadow: none;
}
.video-form-builder-question-drawer .MuiPaper-root > .MuiCardContent-root {
    padding: 0;
}
.screenany-video-player {
    position: relative;
    display: block;
    width: 100% !important;
    height: 0 !important;
    background-color: #000;
    padding: 30% 0;
    border-radius: 5px;
    overflow: hidden;
}
.screenany-video-player.filebox {
    border-radius: 0;
}
.screenany-video-player video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
.screenany-video-player.audio {
    padding: 0;
    height: auto !important;
    background-color: transparent;
}
.screenany-video-player.audio audio {
    position: relative;
    height: 60px !important;
}
.screenany-video-form-builder-question-panel-video-player {
    position: relative;
}
.screenany-video-form-builder-question-panel-video-player .remove-video-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #cf0000;
    color: #fff;
    z-index: 1;
}
.screenany-video-form-builder-question-panel-video-player .remove-video-button:hover {
    background-color: #ff0000;
}
.video-form-builder-question-box-openended-icon {
    padding: 3px;
    margin-right: 4px;
    width: 32px;
    height: 24px;
    background-color: #32cb00;
    color: #fff;
    border-radius: 12px;
    opacity: 0.2;
}
.video-form-builder-question-box-openended-icon.actived {
    opacity: 1;
}
.video-form-builder-question-box-description div p {
    margin: 0;
}
.application-drawer > .MuiPaper-root {
    background-color: #f7f7f7;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
}
.video-form-builder-question-tags-container {
    min-height: 22px;
}
.video-form-builder-question-tags-container .tag-item {

}
.video-form-builder-question-move-updown {
    position: absolute;
    bottom: 20px;
    right: 40px;
}
.field-drawer .MuiPaper-root {
    background-color: #f7f7f7;
    border: 0;
    box-shadow: none;
}
.field-drawer .MuiPaper-root > .MuiCardContent-root {
    padding: 0;
}
button.disabled-hover-effect:hover {
    background-color: #fff;
}
button.disabled-hover-effect:hover .MuiCardActionArea-focusHighlight {
    opacity: 0;
}
.application-row-read1 {
    font-weight: 200;
    background-color: inherit;
}
.application-row-read0 {
    font-weight: 800;
    background-color: #fffbf5;
}
.application-row-selected {
    background-color: rgba(0, 0, 0, 0.04);
}
.campaing-tab-application .application-container {
    border-radius: 0 !important;
}
.campaing-tab-application .application-container .MuiCardContent-root {
    padding-bottom: 0 !important;
}
.uploadbox-image-previewer {
    
}
.uploadbox-image-previewer-img {

}
.screenany-richtextinput-label {
    display: inline-block;
    padding: 0 8px;
    -moz-transform: translate(0, 0) scale(0.75) translateY(13px);
    -ms-transform: translate(0, 0) scale(0.75) translateY(13px);
    transform: translate(0, 0) scale(0.75) translateY(13px);
    color: #9e9e9e;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4375em;
    font-family: 'Roboto',sans-serif;
    background-color: #fff;
}
.screenany-richtextinput .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.screenany-richtextinput .ql-container {
    background-color: #fafafa;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.screenany-richtextinput .ql-container .ql-editor {
    min-height: 200px;
    max-height: 500px;
}
.screenany-skeleton {
    transform: scale(1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
}
ul.screenaby-form-list {
    list-style-type: none;
    margin: 0;
    padding: 0
}
ul.screenaby-form-list li {
    position: relative;
    padding-left: 12px;
}
ul.screenaby-form-list li::before {
    content: "\2022";
    position: absolute;
    top: 0;
    left: 0;
    color: #9e9e9e;
    font-weight: bold;
    display: inline-block; 
}
/* fix quill's list bullet point issue */
.quill.screenany-richtextinput ol li[data-list="bullet"] {
    padding-left: 0px;
    margin-left: 20px;
    list-style-type: disc;
}
.quill.screenany-richtextinput ol li[data-list="bullet"]:before {
    content: "";
}